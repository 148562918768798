<template>
    <h1 style="margin : 30px;">Redirecting to application download page</h1>
</template>
<script>
export default {
    mounted() {
        let os = this.getOperationSystem();
        if (os == 'Android') {
            window.location.href =
                'https://play.google.com/store/apps/details?id=com.pyaesoneshin';
        } else if (os == 'iOS') {
            window.location.href =
                'https://apps.apple.com/mm/app/pyae-sone-shin/id1570012216';
        } else {
            window.location.href = 'https://www.pyaesoneshin.com';
        }
    },

    methods: {
        getOperationSystem() {
            var userAgent =
                navigator.userAgent || navigator.vendor || window.opera;

            // Windows Phone must come first because its UA also contains "Android"
            if (/windows phone/i.test(userAgent)) {
                return 'Windows Phone';
            }

            if (/android/i.test(userAgent)) {
                return 'Android';
            }

            // iOS detection from: http://stackoverflow.com/a/9039885/177710
            if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                return 'iOS';
            }

            return 'unknown';
        },
    },
};
</script>
